@keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@-webkit-keyframes fadeInUp {
  from {
      transform: translate3d(0,40px,0)
  }

  to {
      transform: translate3d(0,0,0);
      opacity: 1
  }
}

@keyframes slideRight {
  from {
    transform: translate3d(-40px,0,0)
  }
  to {
    transform: translate3d(0,0,0);
    display: block;
  }
}